import { Link } from "gatsby";
import React from "react";
import HeroSection from "../common/HeroSection";
import Layout from "../common/Layout";
import BlogItem from "./BlogItem";
import { graphql } from "gatsby";

const BlogList = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const isNext = numPages > currentPage;
  const isPrev = currentPage !== 1;
  return (
    <Layout>
      <HeroSection title="Blog" />
      <section className="residence-section">
        <div className="container">
          <div className="row align-items-stretch">
            {data.allBlogJson.edges.map(({ node }) => (
              <BlogItem {...node} key={node.id} />
            ))}
          </div>
          <div className="row mb-3 mt-3">
            <div className="col-md-12">
              {isPrev && (
                <Link
                  to={`/blog/${currentPage === 2 ? "" : currentPage - 1}`}
                  className="btn btn-light rounded-0 float-left mr-2"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width={20}
                    height={20}
                    stroke="currentColor"
                    strokeWidth={2}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M19 12H5M12 19l-7-7 7-7" />
                  </svg>{" "}
                  Prev
                </Link>
              )}
              {isNext && (
                <Link
                  to={`/blog/${currentPage + 1}`}
                  className="btn btn-black font-weight-normal float-right"
                >
                  Next{" "}
                  <svg
                    viewBox="0 0 24 24"
                    width={20}
                    height={20}
                    stroke="currentColor"
                    strokeWidth={2}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allBlogJson(
      sort: { order: DESC, fields: created_on }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          author
          author_link
          short_desc
          slug
          title
          created_on(locale: "en-IN", formatString: "LL")
          curated_by {
            desc
            title
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          hide_date
          hide_image
        }
      }
    }
  }
`;

export default BlogList;
